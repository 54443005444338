<template>
  <div>
    <v-container>
      <h1 class="pb-6">Shipment Details</h1>
      <h3 class="pb-6">Seller Information</h3>
      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Select Seller/Consignor</h4>
              <v-select outlined></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Shipping Address</h4>

              <v-textarea outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <h4>Point of Origin</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Reference</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Currency</h4>
              <v-select outlined></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <h3 class="py-6">Buyer Information</h3>
      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Select Buyer/Consignor</h4>
              <v-select outlined></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Receiver Address</h4>

              <v-textarea outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <h4>Point of Desination</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Payment Terms</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <h3 class="py-6">Product Information</h3>
      <v-row class="pb-6">
        <v-col cols="6"><v-btn color="primary">Connect to SAP</v-btn></v-col>
        <v-col cols="6">
          <v-btn color="primary">Import Products CSV</v-btn>
        </v-col>
      </v-row>
      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Product Number</h4>
              <v-select outlined></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Product Description</h4>

              <v-textarea outlined></v-textarea>
            </v-col>
            <v-col cols="4">
              <h4>Weight</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <h4>Quanity</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <h4>UOM</h4>

              <v-select outlined></v-select>
            </v-col>
            <v-col cols="4">
              <h4>Cost Per</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <h4>HS Code</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <h4>Duty %</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Subtotal</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Total</h4>

              <v-text-field outlined></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <h3 class="py-6">Shipment Workflow</h3>
      <v-card rounded="lg" class="pa-6">
        <v-timeline dense>
          <v-timeline-item icon="mdi-briefcase">
            Add & Digitize Documents
          </v-timeline-item>
          <v-timeline-item icon="mdi-truck">
            Define Shipping Contract
          </v-timeline-item>
          <v-timeline-item icon="mdi-leak">Add Sensor Triggers</v-timeline-item>
          <v-timeline-item icon="mdi-wallet">Process Payments</v-timeline-item>
          <v-timeline-item icon="mdi-file-document-multiple">
            Define Bill of Lading (BOL)
          </v-timeline-item>
        </v-timeline>
        <v-col class="d-flex justify-center" cols="12">
          <v-btn depressed color="primary">Submit</v-btn>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
